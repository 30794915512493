<template>
  <one-column>
    <template #content>
      <router-link :to="{name: 'dashboard'}">{{ $t('links.toDashboard.label') }}</router-link>
    </template>
  </one-column>
</template>

<script>
import { mapGetters } from 'vuex'
import { appSettings } from '@/settings'
import { ios, android } from 'platform-detect'
import OneColumn from '@/layouts/OneColumn.vue'

export default {
  name: 'AudioKey3',

  components: {
    OneColumn
  },

  metaInfo () {
    return {
      title: 'AudioKey'
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ])
  },

  created () {
    var country = this.user.country
    // user AT as the default country
    if (!country || !appSettings.services.audioKey3[country]) {
      country = 'AT'
    }

    const appStore = appSettings.services.audioKey3[country].ios
    const playStore = appSettings.services.audioKey3[country].android
    const fallBack = appSettings.services.audioKey3[country].fallback
    if (ios) {
      window.location.assign(appStore)
   } else if (android) {
      window.location.assign(playStore)
    } else {
      window.location.assign(fallBack)
    }
  },

  methods: {
  }
}
</script>
